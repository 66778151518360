/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

.CircularProgressbar {
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     *
     * If you're not using "display: flex", you can remove this style.
     */
    width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #BF003B;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: #FFF;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #BF003B;
    font-size: 25px;
    dominant-baseline: middle;
    text-anchor: middle;
}